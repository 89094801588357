
import "babel-polyfill"

import Turbolinks from 'turbolinks'
Turbolinks.start()

import React from 'react'
import ReactDOM from 'react-dom'
import $ from 'jquery'
import 'jquery-ujs'
import 'bootstrap'
import 'bootstrap-datepicker'
import * as NewToastr from 'toastr/toastr';
import 'cocoon';

import { registerComponents, mountComponent } from '../lib/mounter'

import TdsChecker from '../lib/tds_checker'

// Components
import Demo from '../components/stakeholder-map/Demo'
import RangeSlider from '../components/RangeSlider'
import RangeBar from '../components/RangeBar'

import SelectRedirect from '../components/SelectRedirect'
import { Radar } from 'react-chartjs-2'
import { Line } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import ProductForm from '../components/product-form'
window.toastr = require('toastr');

registerComponents({
  RadarChart: Radar,
  LineChart: Line,
  BarChart: Bar,
  RangeBar,
  SelectRedirect,
  Demo,
  RangeSlider,
  ProductForm,
})

if (location.protocol !== "https:") // force https
  location.protocol = "https:"

$(document).on('turbolinks:load', () => {
  $('.react-component').each(function(){
    const name = $(this).data('name')
    const props = $(this).data('props') || {}
    console.log(`Mounting component ${name} with props`, props)

    mountComponent(this, name, props)
  })

  TdsChecker()
  // Initialize Bootstrap Plugins
  $('[data-toggle="tooltip"]').tooltip()

  // Date picker
  $('.datepicker').datepicker({ format: 'dd/mm/yyyy' })

  // Date picker range
  $('.input-daterange').datepicker({ format: 'dd/mm/yyyy' })

  $('#demoModal').modal();

  // Auto hide alert
  setTimeout(() => $('.auto-hide').hide(), 5000)

  $('.pdf-download').click(function() {
    $('.pdf-loader').fadeIn(1000);
  });

  // Scroll to Section
  $('a[href^="#"]').bind('click', function(e) {
    e.preventDefault();
    var target = $(this).attr("href");
    $('html, body').stop().animate({
      scrollTop: $(target).offset().top
    }, 600, function(){
      location.hash = target;
    });
    return false;
  });



  $.fn.dependsOn = function(element, value) {
    var elements = this;
    var hideOrShow = function() {
      var $this = $(this);
      var showEm;
      if ( $this.is('input[type="checkbox"]') ) {
        showEm = $this.is(':checked');
      } else if ($this.is('select')) {
        var fieldValue = $this.find('option:selected').val();
        if (typeof(value) == 'undefined') {
          showEm = fieldValue && $.trim(fieldValue) != '';
        } else if ($.isArray(value)) {
          showEm = $.inArray(fieldValue, value.map(function(v) {return v.toString()})) >= 0;
        } else {
          showEm = value.toString() == fieldValue;
        }
      }
      elements.toggle(showEm);
    }
    $(element).change(hideOrShow);
    $(element).each(hideOrShow);
    return elements;
  };

  $('*[data-depends-on]').each(function() {
    var $this = $(this);
    var master = $this.data('dependsOn').toString();
    var value = $this.data('dependsOnValue');
    if (typeof(value) != 'undefined') {
      $this.dependsOn(master, value);
    } else {
      $this.dependsOn(master);
    }
  });
  $('.mobile-menu-trigger').on('click', function(){
    $('.menu-mobile .menu-and-user').slideToggle(200, 'swing');
    return false;
  });
})


// Active main nav items
$(document).ready(function(){
  $('ul.main-menu li a').click(function(){
    $('ul.main-menu li a').removeClass("active");
    $(this).addClass("active");
  });
});


NewToastr.options = {
  "closeButton": true,
  "debug": false,
  "newestOnTop": false,
  "progressBar": false,
  "positionClass": "toast-top-right",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "1000",
  "timeOut": "0",
  "extendedTimeOut": "0",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
}

var timeoutInfo = {};

$(document).on("click", '.team-report-download-btn', function(){
  const surveyId = this.dataset.surveyId;
  const url = `/surveys/${surveyId}/download_report`;
  fetch(url)
  .then(resp => { 
    if(resp.headers.get("content-type") == "application/pdf"){
      return resp.blob().then(function(blob) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = `team-report-${surveyId}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url); 
      })
    }else{
      return resp.json().then(function(json) {
        const jobId = json.job_id;
        NewToastr.info("Generating report, will be downloaded shortly");
        const refreshUrl = url+`?polling=true&job_id=${jobId}`;
        resp.status == 200 && fetchFile(surveyId, jobId, refreshUrl, 'team-report')
      })
    }
  })
  .catch((e) => {
    NewToastr.clear();
    NewToastr.error('There is some issue in generating report');
    for(const timeoutId of Object.values(timeoutInfo)){
      window.clearTimeout(timeoutId);
    }
  });
})

$(document).on("click", '.team-relationship-map-download-btn', function(){
  const surveyId = this.dataset.surveyId;
  const url = `/surveys/${surveyId}/download_team_map_report`;
  fetch(url)
  .then(resp => { 
    if(resp.headers.get("content-type") == "application/pdf"){
      return resp.blob().then(function(blob) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download = `team-report-${surveyId}.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url); 
      })
    }else{
      return resp.json().then(function(json) {
        const jobId = json.job_id;
        NewToastr.info("Generating report, will be downloaded shortly");
        const refreshUrl = url+`?polling=true&job_id=${jobId}`;
        resp.status == 200 && fetchFile(surveyId, jobId, refreshUrl, 'team-relationship-map')
      })
    }
  })
  .catch((e) => {
    NewToastr.clear();
    NewToastr.error('There is some issue in generating report');
    for(const timeoutId of Object.values(timeoutInfo)){
      window.clearTimeout(timeoutId);
    }
  });
})

$(document).on("click", ".show-np",  function(){
  NewToastr.remove();
  NewToastr.warning("No one has fully completed this survey.");
});
$(document).on("click", "#pulse-report-download-btn",  function(){
  const teamId = this.dataset.teamId;
  const reportName = $(this).data('report-name');
  const url = `/coach_portal/teams/${teamId}/download_pulse_report_pdf`;
    fetch(url)
        .then(resp => {
            return resp.json().then(function(json){
                if(json.success){
                    const url = json.url,
                          encodedURL = btoa(url),
                          completeURL= "https://" + window.location.host + "/report.html?file=" + encodedURL;
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = completeURL;
                    // the filename you want
                    //a.download = `${reportName}-${teamId}.pdf`;
                    document.body.appendChild(a);
                    a.click(); 
                } else{
                    const jobId = json.job_id;
                    NewToastr.info("Generating report, will be downloaded shortly");
                    const refreshUrl = url+`?polling=true&job_id=${jobId}`;
                    resp.status == 200 && fetchFile(teamId, jobId, refreshUrl, reportName);
                }
            })
        })
        .catch((e) => {
            NewToastr.clear();
            NewToastr.error('There is some issue in generating report');
            for(const timeoutId of Object.values(timeoutInfo)){
                window.clearTimeout(timeoutId);
            }
        });
})

function fetchFile(id, jobId, url, reportName){
    fetch(url)
        .then(resp => {
            return resp.json().then(function(json){
                if(json.success){
                    const url = json.url,
                          encodedURL = btoa(url),
                          completeURL= "https://" + window.location.host + "/report.html?file=" + encodedURL;
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.target = '_blank';
                    a.href = completeURL;
                    // the filename you want
                    //a.download = `${reportName}-${id}.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    $("#toast-container").find('.toast-info').first().remove();
                    window.clearTimeout(timeoutInfo[jobId]);
                    NewToastr.success('Report is downloaded');
                } else{
                    if(resp.status == 500){
                        NewToastr.clear();
                        window.clearTimeout(timeoutInfo[jobId]);
                        NewToastr.error('There is some issue in generating report');
                    };
                    if((resp.status == 206) || (resp.status == 504)) { 
                        const timeoutId = setTimeout(() => fetchFile(id, jobId, url, reportName), 5000);
                        timeoutInfo[jobId] = timeoutId;
                    }
                }
            })
        })
        .catch((e) => {
            console.log(e)
            NewToastr.clear();
            NewToastr.error('There is some issue in generating report');
        });
}


window.IsValidEmail = function (email) {
  var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z0-9]{1,}))$/;
  return regex.test(email);
}
