import React from 'react'
import ReactDOM from 'react-dom'

const components = {}

const registerComponents = comps => Object.keys(comps).map((key) => {
  if (!comps.hasOwnProperty(key)) return
  if (components[key]) throw new Error(`Component '${key}' already defined!`)
  components[key] = comps[key]
  console.log(key, 'regd')
})


const mountComponent = (element, name, props = {}) => {
  if (!components[name]) throw new Error(`Component '${name}' not defined!`)

  ReactDOM.render(React.createElement(components[name], props), element)
}

// mountComponent should be used by rails helper
window.mountComponent = mountComponent

export { registerComponents, mountComponent }
