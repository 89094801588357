import React from 'react'
import StakehodlerMap from './'

const IMAGES = [
  '/demo/thumbnail_2.png',
  '/demo/thumbnail_3.png',
  '/demo/thumbnail_4.png',
  '/demo/thumbnail_5.png',
] 

const REL_TYPES = ['close', 'distant', 'hostile', 'cutoff']

export default class Demo extends React.Component {
  state = {
    idCount: 3,
    root: { img: "/demo/thumbnail_1.png" },
    stakeholders: [
      { id: 1, img: IMAGES[0], relation: 'close' },
      { id: 2, img: IMAGES[1], relation: 'hostile' },
    ]
  }

  addNode(){
    const newStakehodler = { 
      id: this.state.idCount, 
      img: IMAGES[Math.floor(Math.random() * IMAGES.length)],
      relation: REL_TYPES[Math.floor(Math.random() * REL_TYPES.length)],
    }

    this.setState({
      stakeholders: [...this.state.stakeholders, newStakehodler],
      idCount: this.state.idCount + 1
    })
  }

  removeNode(){
    this.setState({
      stakeholders: this.state.stakeholders.slice(0, this.state.stakeholders.length - 1)
    })
  }

  render(){
    return (
      <div>
        <button onClick={this.addNode.bind(this)}>Add</button>
        <button onClick={this.removeNode.bind(this)}>Remove</button>
        <span>{this.state.stakeholders.length}</span>
        <br/>
        <StakehodlerMap root={this.state.root} stakeholders={this.state.stakeholders} />
      </div>
    )
  }
}
