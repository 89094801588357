import React from 'react'

import RootStakeholder from './RootStakehodler'
import Stakeholder from './Stakeholder'
import Edge from './Edge'

const WIDTH = 700
const HEIGHT = 700

const RADIUS = WIDTH / 2 - Stakeholder.WIDTH / 2 - 10 

const calculatePosition = (index, count) => {
  const step = (Math.PI * 2) / count
  const CENTER = WIDTH / 2

  const x = Math.round(CENTER + RADIUS * Math.cos(step * index))
  const y = Math.round(CENTER + RADIUS * Math.sin(step * index))

  return { x, y }
}

export default ({ root, stakeholders }) => (
  <svg width={WIDTH} height={HEIGHT}>
    {Stakeholder.DEFS}

    {stakeholders.map((t, i) =>
      <Edge key={t.id} relation={t.relation} pos1={calculatePosition(i, stakeholders.length)} pos2={{ x: WIDTH / 2, y: HEIGHT /2 }} />
    )}

    <RootStakeholder 
      x={WIDTH / 2 - RootStakeholder.WIDTH / 2}
      y={HEIGHT / 2 - RootStakeholder.HEIGHT / 2}
      img={root.img}
    />

    {stakeholders.map((t, i) =>
      <Stakeholder key={t.id} id={t.id} img={t.img} pos={calculatePosition(i, stakeholders.length)} />
    )}
  </svg>
)
