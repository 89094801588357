import React from 'react'

const redirectWithPath = (path, value) => {
  Turbolinks.visit(path.replace(':value', value).replace('%3Avalue', value))
}

export default ({ values, value, path }) => (
  <select className="custom-select" value={value} onChange={(e) => redirectWithPath(path, e.target.value)}>
    { values.map((t) => <option key={t[0]} value={t[0]}>{t[1]}</option> )}
  </select>
)
