import React from 'react'
import Slider from 'react-rangeslider'

export default class RangeSlider extends React.Component {

  constructor(props){
    super(props)
    this.state = { value: props.value || 0 }

    if(this.state.value != 0 && props.negative) this.state.value = 6 - props.value
  }

  getValue(){
    if(this.props.negative) return 6 - this.state.value
    else return this.state.value
  }

  render(){
    var labels = {}
    for(var i=this.props.min; i<=this.props.max; i++)labels[i] = i.toString()

    return (
      <div>
        <input min={this.props.min} max={this.props.max} step={this.props.step} type="number" style={{display: 'none'}} name={this.props.name} value={this.getValue()} />
        <div className="rangebar__label-left text-muted">{this.props.lLabel || ""}</div>
        <div className="rangebar__label-right text-muted">{this.props.rLabel || ""}</div>
        <Slider 
            className={this.state.value == 0 ? 'empty' : ''} 
            {...this.props} 
            value={this.state.value == 0 ? 3 : this.state.value} 
            onChange={value => this.setState({ value })} 
            labels={labels}
        />
      </div>
    )
  }
}
