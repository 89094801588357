import React, { useState } from 'react'

const ProductForm = ({ organization, team, product_types, sector_types, price_map, country_band }) => {
  const [productType, setProductType] = useState()
  const [credits, setCredits] = useState(0)

  const handleProductChange = (e) => {
    if(e.target.value) {
      setProductType(product_types[e.target.value])
      setCredits(price_map[e.target.value][country_band][organization.sector_type])
    }
  }

  return (
    <React.Fragment>
      <div className="form-group">
        <label>Organisation</label>
        <input value={organization.name} disabled className="form-control" />
      </div>
      <div className="form-group">
        <label>Credits</label>
        <input value={organization.credits} disabled className="form-control" />
      </div>
      <div className="form-group">
        <label>Sector Type</label>
        <input value={sector_types[organization.sector_type]} disabled className="form-control" />
      </div>
      <div className="form-group">
        <label>Country Code <b>({ country_band })</b></label>
        <input value={team.country_code} disabled className="form-control" />
      </div>
      <div className="form-group">
        <label>Available teamSalient® product</label>
        <select className="form-control" onChange={handleProductChange}>
            <option></option>
          { Object.keys(product_types).map((key) => (
            <option value={key} key={key}>{ product_types[key].name }</option>
          )) }
        </select>
      </div>
      <input type="hidden" name="product[product_type]" value={productType?.id} />
      <input type="hidden" name="product[available_surveys]" value={productType?.available_surveys} />
      <input type="hidden" name="product[duration]" value={productType?.duration} />
      <input type="hidden" name="product[credits]" value={credits} />
      { productType && (
        <div className="alert alert-success">{`${productType.name} requires ${credits} credits (£${ (credits * 50).toFixed(2) })`}</div>
      )}
    </React.Fragment>
  )
} 

export default ProductForm;