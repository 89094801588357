import React from 'react'

const RELATION_STYLES = {
  close: { 
    strokeDasharray: "5, 5",
    style: { stroke: 'rgb(0,255,0)', strokeWidth: 3 },
  },
  hostile: { 
    style: { stroke: 'rgb(255,0,0)', strokeWidth: 2 },
    strokeDasharray: 0.9
  },
  distant: { 
    style: { stroke: 'rgb(255,159,0)', strokeWidth: 2 },
    strokeDasharray: "5, 10"
  },
  cutoff: { 
    style: { stroke: 'rgb(255,0,150)', strokeWidth: 2 },
    strokeDasharray: '5, 5, 1, 5'
  },
}

export default ({ pos1, pos2, relation }) => (
 <line className="stakeholder-edge" x1={pos1.x} y1={pos1.y} x2={pos2.x} y2={pos2.y} {...RELATION_STYLES[relation]} />
)
