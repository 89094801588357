import React from 'react'

const percent = (total, value) => `${parseInt((value - 1) / (total - 1) * 100)}%`

export default ({ min, max, value, total, lLabel, rLabel, negative, teamleader, sponsors }) => {
  value = parseFloat(value)
  teamleader = parseFloat(teamleader)
  sponsors = parseFloat(sponsors)

  if(negative){
    const t = min
    min = 6 - max
    max = 6 - t

    value = 6 - value
    teamleader = 6 - teamleader
  }

  return (
    <div className="rangebar">
      <div className="rangebar__label-left text-muted">{lLabel || "1.0"}</div>
      <div className="rangebar__label-right text-muted">{rLabel || "5.0"}</div>


      { isNaN(value) ? null :
        <div>
          <div
          className="rangebar__fill"
          style={{ left: percent(total, min), width: percent(total, max - min + 1) }}
          ></div>
          <div className="rangebar__average" style={{ left: percent(total, value) }}>{value.toFixed(1)}</div>
        </div>
      }

      { isNaN(teamleader) ? null :
        <div className="rangebar__team_leader" style={{ left: percent(total, teamleader) }}>{teamleader.toFixed(1)}</div>
      }
      {
        isNaN(sponsors) ? null :
        <div className="rangebar__sponsors" style={{ left: percent(total, sponsors) }}>{sponsors.toFixed(1)}</div>
      }
    </div>
  )
}
