import $ from 'jquery'


export default () => {
  const formElement = $('#enduser-tds-form')
  if (formElement == null || formElement[0] == null) return

  formElement.find('button.btn').click((e) => {
    const inputs = formElement.find('input[type="number"]')
    inputs.each(function() {
      $(this).css('display', 'block') // Show the input
    })

    const values = inputs.map(function() {
      return $(this).val()
    }).toArray().map((t) => parseFloat(t)) // Use parseFloat for decimal numbers
      .filter((t) => t === 0) // Use strict equality to filter out zero values

    if (values.length > 0) {
      $('#tds-select-error').show()
      e.preventDefault()
    }

    inputs.each(function() {
      $(this).css('display', 'none') // Hide the input again
    })
  })
}
