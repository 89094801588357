import React from 'react'

const WIDTH = 80
const HEIGHT= 80

const Stakeholder = ({ pos, id, img }) => (
  <g className="stakeholder" transform={`translate(${pos.x - WIDTH / 2}, ${pos.y - HEIGHT / 2})`}>
    <image width={WIDTH} height={HEIGHT} href={img} clipPath="url(#stakeholderClip)" />
  </g>
)

Stakeholder.DEFS = (
  <defs>
    <clipPath id="stakeholderClip">
      <circle cx={WIDTH/2} cy={HEIGHT/2} r={WIDTH/2} fill="#FFF"/>   
    </clipPath>
  </defs>
)

Stakeholder.WIDTH = WIDTH
Stakeholder.HEIGHT = HEIGHT
export default Stakeholder
