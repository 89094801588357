import React from 'react'

const WIDTH = 100
const HEIGHT= 100

const RootStakeholder = ({ x, y, img }) => (
  <g transform={`translate(${x}, ${y})`}>
    <defs>
      <clipPath id="rootCircleView">
        <circle cx={WIDTH/2} cy={HEIGHT/2} r={WIDTH/2} fill="#FFF"/>   
      </clipPath>
    </defs>

    <image width={WIDTH} height={HEIGHT} href={img} clipPath="url(#rootCircleView)" />
  </g>
)


RootStakeholder.WIDTH = WIDTH
RootStakeholder.HEIGHT = HEIGHT
export default RootStakeholder
